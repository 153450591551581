import React from "react"
import Layout from "../components/layouts/Layout2"
import { Hero, Map, Breadcrumb } from "../components/slices"
import { graphql, PageProps, Link } from "gatsby"
import { IconFacebook, IconInstagram } from "../components/svg"
import { Helmet } from "react-helmet"
import { renderMetaTags } from "../utils"

const MeetTheTeam = ({ data, location }: PageProps) => {
  const { allDatoCmsTeamListing, allDatoCmsTeamMember } = data
  let breadcrumbData: breadcrumbItem[] = [{ title: "Home", url: "/" }]

  breadcrumbData = [
    ...breadcrumbData,
    {
      title: allDatoCmsTeamListing.nodes[0].title,
      url: location.pathname,
    },
  ]

  const seoData = {
    ...allDatoCmsTeamListing.nodes[0].seo,
    image: allDatoCmsTeamListing.nodes[0].image,
    url: location.pathname,
  }

  const heroProps = {
    title: allDatoCmsTeamListing.nodes[0].title,
    variation: "small",
  }
  return (
    <Layout>
      {allDatoCmsTeamListing.nodes[0].seoMetaTags.tags && (
        <Helmet>
          {renderMetaTags(allDatoCmsTeamListing.nodes[0].seoMetaTags.tags)}
        </Helmet>
      )}
      <Hero {...heroProps} />
      <div className="container -pinched">
        <Breadcrumb data={breadcrumbData} />
      </div>
      {allDatoCmsTeamListing.nodes[0].content && (
        <div className="[ section -small ]">
          <div className="intro">
            <div className="[ container -pinched ]">
              <p>{allDatoCmsTeamListing.nodes[0].content}</p>
            </div>
          </div>
        </div>
      )}
      {allDatoCmsTeamMember.nodes.map((teamMember, index) => {
        const {
          firstName,
          name,
          shortBio,
          instagram,
          twitter,
          facebook,
          url,
          photo,
        } = teamMember
        return (
          <div className="section" key={`team-member-${index}`}>
            <div className="container">
              <div className="team">
                <div className="about-me__inner">
                  <div className="about-me__content">
                    <div className="about-me__content-inner">
                      <h3 className="[ title h3 -underline ]">
                        <span>{name}</span>
                      </h3>
                      <div className="social-icons -horizontal">
                        {instagram && (
                          <a
                            href={instagram}
                            className="social-icons__icon -instagram"
                          >
                            <IconInstagram />
                          </a>
                        )}
                        {twitter && (
                          <a
                            href={twitter}
                            className="social-icons__icon -twitter"
                          >
                            <IconFacebook />
                          </a>
                        )}
                        {facebook && (
                          <a
                            href={facebook}
                            className="social-icons__icon -facebook"
                          >
                            <IconFacebook />
                          </a>
                        )}
                      </div>
                      <p>{shortBio}</p>
                      <p>
                        <Link to={url} className="button">
                          Read more
                        </Link>
                      </p>
                    </div>
                    <p className="handwriting">{firstName}</p>
                  </div>
                  <div className="about-me__media">
                    <img src={photo.url} alt={name} loading="lazy" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <Map />
    </Layout>
  )
}

export const query = graphql`
  query MeetTheTeam {
    allDatoCmsTeamListing {
      nodes {
        title
        content
        url
        image {
          url
        }
        seoMetaTags {
          tags
        }
      }
    }
    allDatoCmsTeamMember {
      nodes {
        name
        firstName
        shortBio
        instagram
        twitter
        facebook
        url
        photo {
          url
        }
      }
    }
  }
`

export default MeetTheTeam
